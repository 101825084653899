





























































import { Component, Vue, Prop } from 'vue-property-decorator';

import Thumb from '@/components/Thumb/index.vue';

import { Doubt } from '@/globalInterfaces/Doubts';

import LightBoxImage from '@/mixins/LightBoxImage';

const USER_TYPE_NUMBER_MONITOR = 0;

@Component({
  mixins: [LightBoxImage],
  components: {
    Thumb,
  },
})
export default class ReplyDoubt extends Vue {
  @Prop({ required: true }) reply!: Doubt;
  @Prop({ default: 'Resposta' }) title!: string;

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get userTypeName() {
    return this.reply.user_type === USER_TYPE_NUMBER_MONITOR
      ? 'Monitor'
      : 'Aluno';
  }
}
