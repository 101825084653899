import { render, staticRenderFns } from "./ShowDoubtsLoading.vue?vue&type=template&id=1e680388&scoped=true&"
import script from "./ShowDoubtsLoading.vue?vue&type=script&lang=ts&"
export * from "./ShowDoubtsLoading.vue?vue&type=script&lang=ts&"
import style0 from "./ShowDoubtsLoading.scss?vue&type=style&index=0&id=1e680388&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e680388",
  null
  
)

export default component.exports